@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900&display=swap");


body {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 1.8 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.carousel-data {
  background-color: #095F60 !important;
  /* Set a background color */
  width: 100% !important;
  /* Take full width */
  height: 824px !important;
  /* Set the height to match your design */
  position: relative !important;
  /* Optional: Position relative based on your layout */
  /* opacity: 1; */
  /* Make it visible */
}



.custom-button:hover {
  background-color: #095F60 !important;
  color: white !important;
}


.lastestArticles {
  background: #F2F2F2 !important;
  width: 100%;
  height: 100%;
  top: 2180px;
  gap: 0px;
  /* opacity: 0px; */

}

.article-open{
  border: 1px solid rgba(255, 185, 1, 1) !important;
  background: rgba(251, 247, 234, 1) !important;
  width: 146px;
  height: 37px;
  padding: 8px 0px 0px 0px;
  gap: 10px;
  border-radius: 8px !important; 
}

.sticky {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Adjust z-index as needed */
  border-bottom: 1px solid white;
  color: #241a17 !important;
  /* box-shadow: 0 2px 4px rgba(36, 26, 23, 0.5); */
}

.carousel-des{
  /* font-size: 64px; */
/* font-weight: 500; */
/* line-height: 96px; */
text-align: left;
/* color: var(--color-white-solid, rgba(255, 255, 255, 1)); */
/* width: 438px; */
/* height: 288px; */
/* top: 227px; */
/* left: 150px; */
gap: 0px;
/* opacity: 0px; */

}
.article-abstract-btn{
padding: 6.5px 12px 7.5px 12px;
gap: 0px;
border-radius: 6px 0px 0px 0px;
/* opacity: 0px; */
background: rgba(255, 253, 247, 1) !important;
border: 1px solid rgba(165, 165, 165, 1) !important;
width: 175px !important;
height: 35px !important;
color: #000 !important;
font-size: 16px;
font-weight: 400;
line-height: 20.8px;
text-align: center;

/* background: rgba(0, 0, 0, 1); */

}

.article-open-text{
font-size: 16px;
font-weight: 600;
line-height: 20.8px;
text-align: left;
color: rgba(255, 185, 1, 1);


}

.article-title {
  background: rgba(251, 247, 234, 1) !important;
  height:77px;
  padding: 16px 16px 24px 16px;
  gap: 10px;
  border-radius: 8px 8px 0px 0px;
  /* opacity: 0px; */
  padding: 0px !important;
}
.article-des{
font-size: 20px;
font-weight: 600;
line-height: 20.8px;
text-align: left;
color: rgba(0, 0, 0, 1);

}

.bg-carousel-card {
  background: #FFC500 !important;
  /* width: 778px; */
  height: 353px;
  gap: 0px;
  border-radius: 24px;
  /* color: white; */
}

.bg-carousel-aim {
  /* width: 778px; */
  height: 180px;
  top: 712px;
  left: 110px;
  padding: 16px 22px 16px 22px;
  gap: 10px;
  border-radius: 24px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
  background: linear-gradient(90deg, #01333b 0%, #2d6466 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  
}

.card-container {
  background: linear-gradient(90deg, #01333b 0%, #2d6466 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  /* width: 778px; */
  height: 128px;
  top: 564px;
  left: 110px;
  padding: 20px 24px 20px 24px;
  gap: 10px;
  border-radius: 24px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
}
.aim-title{
font-size: 24px;
font-weight: 700;
line-height: 31.2px;
text-align: left;
color: #FFFDF7;

}
.aim-content{
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  
}

.card-content {
  color: #ffffff;
}

.card-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  
}

.card-text {
font-size: 20px;
font-weight: 400;
line-height: 26px !important;
text-align: left;

}

.navbar-bottom {
  border-bottom: 2px solid black !important;
}

.custom-editor-height .ck-editor__editable {
  min-height: 500px;
  /* Adjust the height as needed */
  max-height: 600px;
  /* Set maximum height if needed */
  overflow-y: auto;
  /* Allow vertical scrolling if content exceeds max height */
}

.carousel-image {
  width: 100% !important;
  height: 350px !important;
  object-fit: cover;
  /* This will ensure the image scales and maintains aspect ratio */
}

.custom_spinner {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100% !important;
  width: 100vw !important;
  background: rgba(0, 0, 0, 0.3) !important;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-width {
  width: 50px !important;
}

.loader {
  aspect-ratio: 1;
  display: grid;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  border-right-color: white;
  animation: l15 1s infinite linear;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}

.loader::after {
  margin: 8px;
  animation-duration: 3s;
}


.not-found-container {
  text-align: center;
  margin-top: 50px;
}

.not-found-title {
  font-size: 48px;
  color: #333;
}

.not-found-message {
  font-size: 18px;
  color: #666;
}

.not-found-link {
  display: inline-block;
  margin-top: 20px;
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
}

.not-found-link:hover {
  text-decoration: underline;
}



.footer a {
  color: #000;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.logo-size {
  width: 300px;
  height: 110px;
}


.social-icons a {
  color: #555;
}

.social-icons a:hover {
  color: #007bff;
}

.side-heading {
  font-family: "Poppins", sans-serif !important;
  color: #F5BE18;
  font-weight: bold;
}

.button-color {
  background-color: #095F60 !important;
  color: white !important;
  border-radius: none !important;
  border: none !important;
}

.bg-color {
  background-color: #095F60 !important;

}

.primary-bg {
  background-color: #1d130f !important;
}

.sidebar_a {
  height: calc(100vh - 68px) !important;
  border-right: 1px solid #f5821f;
  background-color: #1d130f !important;
  color: white;
}


.side-font {
  font-family: "Poppins", sans-serif !important;
  font-weight: bold;
}

.navbar-collapse .navbar-nav .nav-link {
  font-size: 20px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  line-height: 24px;
  color: black;

}

.side-paragraph {
  font-family: cursive;
}

.active {
  font-weight: bold;
  color: #095F60 !important;

}

.nav-item .dropdown-menu {
  color: black ;
  font-family: "Poppins", sans-serif !important;
  font-weight: bold;
  font-size: 20px;
}

/* Custom styles to remove the background color for active links */
.nav-link.active,
.dropdown-item.active {
  background-color: transparent !important;
  /* Remove background color */
  color: inherit;
  /* Keep the text color as it is */
}

.login-form {
  border: 1px solid lightgrey;
  border-radius: 10px;
  /* box-shadow: 1px 1px 10px #00696C; */
  box-shadow: 1px 1px 10px #f5821f;
}

.custom-bg-btn {
  background-color: #f5821f;
  border-radius: 10px;
}

.text-color {
  /* color: #f5821f; */
  color: white;


}

.bg-image {
  position: relative;
  /* Set position to relative */
  width: 100%;
  background-image: url('./assets/images/slider/four.jpg');
  background-size: cover;
  background-position: center;
}



.table_new_ui th {
  border: none;
}

.table_new_ui th {
  /* background-color:#00696C !important; */
  background-color: #1d130f !important;
  color: white !important;
}

.table-header {
  /* background-color:#00696C !important; */
  background-color: #1d130f !important;
  color: white !important;
}

.table_new_ui th,
.table_new_ui td {
  font-size: 12px;
  color: #333333;
  font-weight: 400;
}


.scrollable-top {
  max-height: 400px;
  /* Adjust height as needed */
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}

.scrollable-top h5 {
  margin-bottom: 10px;
}


.admin-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: -30px;
  /* Position the icon over the card */
  z-index: 1;
}

.home-icon {
  font-size: 40px;
  color: #333;
  background-color: #fff;
  padding: 10px;
  border-radius: 50%;
  border: 2px solid #ddd;
}

.card1 {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 60%;
  text-align: left;
  margin-top: 20px;
}

.card1 h2 {
  margin-bottom: 15px;
}

.card1 p {
  margin: 5px 0;
}

.card1 a {
  color: #007bff;
  text-decoration: none;
}

.card1 a:hover {
  text-decoration: underline;
}